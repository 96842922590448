import RestrictionService from '@/services/RestrictionService.js';

let initialTargetData = {
  firstName: '',
  secondName: '',
  firstSurname: '',
  secondSurname: '',
  identification: '',
  identificationType: 'CC',
  identificationDate: '',
}

const restrictions = {
  namespaced: true,

  state: {
    // variable para almacenar el id de la tarea celery
    celeryTaskId: '',
    reportGenerated: false,
    reportLoading: false,
    reportLink: '',
    reportCode: '',
    reportOwner: localStorage.getItem('userName') || '',
    reportProject: '',
    sources: [],
    // estado que almacena listado reportes de listas restrictivas
    reports: [],
    selectedProject: '',
    selectedReports: [],
    selectedSources: [],
    naturalPerson: true,
    identificationTypes: [
      {
        id: 'CC',
        name: 'Cédula de Ciudadanía',
        person: 'natural'
      },
      {
        id: 'CE',
        name: 'Cédula de Extranjería',
        person: 'natural'
      },
      {
        id: 'DP',
        name: 'Documento País de Origen',
        person: 'natural'
      },
      {
        id: 'MM',
        name: 'Matricula Mercantil',
        person: 'company'
      },
      {
        id: 'PA',
        name: 'Pasaporte',
        person: 'natural'
      },
      {
        id: 'PEP',
        name: 'Permiso Especial de Permanencia',
        person: 'natural'
      },
      {
        id: 'PPT',
        name: 'Permiso por Protección Temporal',
        person: 'natural'
      },
      {
        id: 'NIT',
        name: 'Número de Identificación Tributaria',
        person: 'company'
      },
      
      {
        id: 'TI',
        name: 'Tarjeta de Identidad',
        person: 'natural'
      },
    ],
    targetData: {...initialTargetData}
  },

  actions: {
    async getLists({ commit, state }) {
      try {
        const token = localStorage.getItem('token');
        const response = await RestrictionService.getLists(token);
        if (response) {
          const sources = response.data;
          for (let index = 0; index < sources.length; index++) {
            const element = sources[index];
            element.status = '';
          }
          state.sources = response.data;
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al conseguir las fuentes
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async uploadSearch({ commit, state }, selectedKeys) {
      commit('SET_SEARCHING_SOURCES', selectedKeys);
      // resetear el id de la tarea celery
      state.celeryTaskId = '';
      try {
        // enviar las fuentes en el formato adecuado
        const sources = []
        for (let index = 0; index < state.sources.length; index++) {
          const source = state.sources[index];
          if (selectedKeys.includes(source.key)) {
            sources.push({
              key: source.key,
              desc: source.desc,
              name: source.name
            })
          }          
        }
        const selectedReport = state.reports.filter((x)=>x.internal_code===state.selectedProject)[0]
        const requestData = {
          code: state.reportCode,
          project: state.reportProject,
          owner: state.reportOwner,
          subject_data: state.targetData,
          sources,
          template : selectedReport.template_name,
          list_search: selectedReport.list_search,
        }
        state.reportLoading = true;
        const token = localStorage.getItem('token');
        const response = await RestrictionService.uploadSearch(requestData, token);
        if (response) {
          console.log('entro respuesta RESTRICTED', response);
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se envió correctamente la lista al sistema',
            alertType: 'success'
          }, {root: true});
          // asignar el id de la tarea de celery
          state.celeryTaskId = response.data.task_id;
        }
      } catch (error) {
          console.log(error);
          state.reportLoading = false;
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al consultar las listas restrictivas
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    // acción para obtener listado reportes para listas restrictivas
    async getReports({ commit, state }) {
      try {
        const token = localStorage.getItem('token');
        const response = await RestrictionService.getReports(token);
        if (response) {
          state.reports = response.data;
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al conseguir las fuentes
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async taskTimeoutFinished({ commit, state}, { taskId, message }) {
      /**
       * Acción que maneja el evento en el cual el servidor finaliza la tarea por
       * que se ha superado el limite de tiempo
       *
       * @param {Object} contexto contexto de la acción
       * @param {Object} payload datos de la acción
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('taskTimeoutFinished', {
       *  taskId: '5e8c9923-4a33-40ae-b530-ccd56056a709',
       *  message: 'mensaje de error',
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      // resetera el id de la tarea celery
      if (state.celeryTaskId === taskId) {
        state.celeryTaskId = '';
      }
      // mostrar alerta indicado del error
      commit('ui/SHOW_ALERT',{
        alertMessage: message,
        alertType: 'error'
      }, {root: true});
      // limpiar interfaz de consulta listas restrictivas
      const progressSources = state.sources.filter((x) => x.status === 'search');
      for (let index = 0; index < progressSources.length; index++) {
        const source = progressSources[index];
        source.status = 'error';
        source.error = message;
      }
      // indicar que la carga ha parado
      state.reportLoading = false;
    },
  },

  mutations: {
    CHANGE_INITIAL_ID_TYPE(state) {
      state.targetData.identificationType = state.naturalPerson ? 'CC' : 'NIT';
    },
    CHANGE_VALUE(state, {value, varName}) {
      state[varName] = value;
    },
    REPORT_GENERATED(state, {link}) {
      state.reportGenerated= true
      state.reportLink= link
      state.reportLoading= false
    },
    REFRESH_SEARCH(state) {
      state.reportGenerated= false;
      state.reportLink= ''
      state.targetData = {...initialTargetData};
      state.reportLoading= false;
      // seleccionar el tipo de identificacion segun el tipo de persona
      state.targetData.identificationType = state.naturalPerson ? 'CC' : 'NIT';
      // inicializar las busquedas
      for (let index = 0; index < state.sources.length; index++) {
        const element = state.sources[index];
        element.status = '';
        element.error = '';
      }
    },
    SET_SEARCHING_SOURCES(state, selectedKeys) {
      for (let index = 0; index < state.sources.length; index++) {
        const element = state.sources[index];
        if (selectedKeys.includes(element.key)) {
          element.status = 'search';
        }
      }
    },
    UPDATE_SOURCE(state, {source, error, locatedName, locatedId}) {
      let status = '';
      let sourceFound = locatedName;
      if (locatedName !== 'Sin Informacion' && locatedName !== 'No Aplica') {
        sourceFound = locatedName;
      }
      if (locatedId !== 'Sin Informacion' && locatedId !== 'No Aplica') {
        sourceFound = locatedId;
      }
      status = sourceFound === true ? 'found' : 'not_found';
      switch (sourceFound) {
        case 'Si':
          status = 'found';
          break;
        case 'No':
          status = 'not_found';
          break;
        case 'Sin Informacion':
          status = 'without_info';
          break;
        case 'No Aplica':
          status = 'without_info';
          break;
        case 'Error':
          status = 'error';
          break;
        default:
          status = 'without_info';
          break;
      }
      for (let index = 0; index < state.sources.length; index++) {
        const element = state.sources[index];
        if (element.key === source) {
          element.status = status;
          element.error = error;
        }
      }
    },
    // Mutación para actualizar los tipos de identificación para consulta VUR
    SET_VUR_IDS_TYPES(state) {
      // listado de identificaciones no presentes en el VUR
      const LIST_IDS_NO_VUR = ['DP', 'PEP', 'PPT', 'MM']
      state.identificationTypes = state.identificationTypes.filter((x) => !LIST_IDS_NO_VUR.includes(x.id))
    },
    // Mutación para borrar los datos de nombre
    CLEAR_NAMES(state) {
      state.targetData.firstName = '';
      state.targetData.secondName = '';
      state.targetData.firstSurname = '';
      state.targetData.secondSurname = '';
    },
    // reiniciar las fuentes despues de cancelar
    RESTART_SOURCES_ITEMS(state) {
      for (let index = 0; index < state.sources.length; index++) {
        const element = state.sources[index];
        element.status = '';
        element.error = '';
      }
    }
  },
  
  getters: {
    activeSources: (state) => state.sources.map((x, index) => x.active === true ? index: -1).filter((x) => x > -1),
    activeSourcesList: (state) => state.sources.filter((x) => x.active === true),
  }
};

export default restrictions;