<template lang="pug">
v-dialog(
  v-model="bottomModalShow"
  fullscreen
  hide-overlay
  transition="dialog-bottom-transition")
  v-card
    v-toolbar(
      dark
      color="teal")
      v-toolbar-title(v-html="bottomModalTitle")
      v-spacer
      v-toolbar-items
      v-btn(
        icon
        dark
        @click="CLEAR_BOTTOM_MODAL()")
        v-icon mdi-close
    v-card-text(class="px-0 py-0")
      component(
        v-show="bottomModalComponent !== ''"
        class="my-2 px-2"
        v-bind:is="bottomModalComponent"
        v-bind="bottomModalProperties")
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CoordinateManager from '@/components/geos/CoordinateManager';

export default {
  name: 'CommonBottomModal',

  components: {
    CoordinateManager
  },

  data() {
    return {
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },

  watch: {
    bottomModalComponent: {
      handler(newVal) {
        console.log('entro watch bottom', newVal);
      }
    }
  },

  computed: {
    ...mapState('ui', ['bottomModalTitle', 'bottomModalComponent', 'bottomModalProperties']),
    bottomModalShow: {
      get() {
        return this.$store.state.ui.bottomModalShow;
      },
      set(value) {
        return this.$store.commit('ui/CHANGE_VALUE', {
          varName: 'bottomModalShow',
          value
        });
      }
    }
  },

  methods: {
    ...mapMutations('ui', ['CLEAR_BOTTOM_MODAL']),
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
