<template lang="pug">
v-card(
  class="mx-auto"
  max-width="80%")
  //- link escondido para descargar el reporte
  a(ref="downloadLink" hidden download="massive_report.xlsx")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Calculadora Geodésica OnLine
  v-divider
  v-row(class="mt-2 mx-3")
    //- Visor geografico de las coordenadas
    v-col(cols="7")
      div(style="height: 500px; width: 100%")
        l-map(
          @click="mapClick"
          :zoom="zoom"
          :options="mapOptions"
          ref="myMap"
          :center="center"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
          @ready="lefletMapReady()")
          //- polygono de los linderos
          l-polygon(
            ref="polygon"
            :lat-lngs="polygonCoords"
            @ready="polygonReady"
            :color="polygonColor"
            :weight="4"
            :fill-color="polygonColor")
          //- los marcadores de las coordenadas
          l-marker(
            v-for="c in coordinates"
            :icon="icon"
            :lat-lng="latLng(c.lat, c.lon)"
            :key="`${c.lat}-${c.lon}`")
            l-tooltip hola
          //- control de las capas del mapa
          l-control-layers
          //- capas base y superpuestas del mapa
          l-wms-tile-layer(
            v-for="l in layers"
            :key="l.name"
            :base-url="l.url"
            :layer-type="l.type"
            :layers="l.layers"
            :transparent="l.transparent"
            format="image/png"
            :name="l.name")
    v-col(class="text-left" cols="5")
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-1-circle
        | Puede cargar coordenadas desde un archivo Excel
      v-file-input(
        :disabled="true"
        filled
        dense
        class="mx-4"
        v-model="file"
        :rules="rules"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        placeholder="Seleccione un archivo"
        prepend-icon="mdi-file-excel"
        ref="fileExcel"
        label="Listado números de coordenadas")
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-2-circle
        | Seleccione sistema de entrada (aplica para coordenadas cargadas)
      crs-select(v-model="inputCrs" :disabled="true")
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-3-circle
        | Seleccione sistema de destino
      crs-select(v-model="outputCrs")
      span(class="text-left")
        v-icon(color="red" size="30") mdi-numeric-4-circle
        | Tomar coordenadas del mapa
      v-switch(
        v-model="enableDraw"
        inset
        :label="`Dibujar coordenadas: ${enableDraw ? 'SI' : 'NO'}`")
  v-divider(class="my-3")
  //- Sección con la tabla de coordenadas
  coordinates-preview(
    class="mx-2"
    :coordinates="coordinates"
    @deleteCoordinate="deleteCoord")
  v-divider(class="mt-3")

  v-card-actions
    v-btn(
      small
      color="red"
      class="ma-2 white--text"
      @click="coordinates = []")
      v-icon(left dark) mdi-broom
      | limpiar puntos
    v-spacer
    v-btn(
      small
      title="Descargar excel coordenadas"
      color="info"
      class="ma-2 white--text"
      @click="download")
      v-icon(left dark) mdi-download
      | Descargar Excel
    v-btn(
      @click=""
      :loading="false"
      :disabled="true"
      title="Descargar KML de los puntos"
      class="info"
      small
      color="white")
      v-icon(left dark) mdi-map-marker-multiple-outline
      | Descargar kml
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { latLng } from "leaflet";
import { makeid, downloadExcel } from '@/utils.js';
import mapMixin from '@/mixins/mapMixin.js';
import ExcelFileAction from '@/components/ui/ExcelFileAction';
import ReportPreview from '@/components/reports_cmp/ReportPreview';
import CrsSelect from '@/components/geos/CrsSelect';
import proj4 from 'proj4';
import {
  LMap,
  LWMSTileLayer,
  LControlLayers,
  LControl,
  LControlScale,
  LMarker,
  LPolygon,
  LTooltip,
} from "vue2-leaflet";
import CoordinatesPreview from '@/components/geos/CoordinatesPreview';

export default {
  name: 'CoordinateManager',

  components: {
    ExcelFileAction,
    ReportPreview,
    CrsSelect,
    LMap,
    LControlLayers,
    'l-wms-tile-layer': LWMSTileLayer,
    LControl,
    LControlScale,
    CoordinatesPreview,
    LMarker,
    LPolygon,
    LTooltip
  },

  mixins: [mapMixin],

  props: {
    // propiedad con coordenadas iniciales
    initialCoordinates: {
      type: Array,
      default: () => []
    },
    // propiedad para el sistema de referencia origen inicial
    initialOriginSrs: {
      type: String,
      default: ''
    },
    // propiedad para el sistema de referencia destino inicial
    initialTargetSrs: {
      type: String,
      default: ''
    },
    // propiedad para agregar el poligono inicial en caso linderos
    addInitialPolygon: {
      type: Boolean,
      default: false
    },
    // propiedad que indica si todas las funcionalidades estarán activas
    modeActive: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    // el arcghivo excel con los folios
    file: null,
    // estado que almacena el crs de entrada
    inputCrs: null,
    // estado que almacena el crs de entrada 
    outputCrs: null,
    // indica si se encontro error en el archivo excel
    rules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
    ],
    // lista de objetos con las coordenadas
    coordinates: [],
    // coordenadas del polygono
    polygonCoords: [],
    // indica si se agregan coordenadas haciendo click
    enableDraw: true
  }),

  computed: {
    ...mapGetters('geos', ['getProj4']),
    ...mapState('geos', {
      systems:'coordinateSystems',
      layers: 'layers'
    }),
  },

  watch: {
    initialCoordinates: {
      handler(newVal) {
        console.log('watch coordinates', newVal);
        this.renderInitialData();
      }
    }
  },

  mounted() {
    // limpiar los datos
    this.coordinates = [];
    this.renderInitialData();
  },

  methods: {
    // metodo ṕara agregar una coordenada
    addCoordinate(coordinate, inputCrs, outputCrs) {
      // agragar proyeccion origen a proj4
      proj4.defs(inputCrs, this.getProj4(inputCrs));
      // agragar proyeccion destino a proj4
      proj4.defs(outputCrs, this.getProj4(outputCrs));
      // crear la transformacion
      const transformation = (coords) => proj4(inputCrs, outputCrs).forward(coords, true);
      const coords = transformation(coordinate);
      // variable para almacenar las coordenadas geograficas leaflet
      let coordsGeo = coords;
      if (outputCrs !== 'EPSG:4326') {
        const transformation4326 = (coords) => proj4(inputCrs, 'EPSG:4326').forward(coords, true);
        coordsGeo = transformation4326(coordinate);
      }
      // gernera el nombre de la coordenadas
      const name = `VERTICE ${this.coordinates.length + 1}`;
      this.coordinates.push({
        id: makeid(8),
        name,
        xOrigin: coordinate[0],
        yOrigin: coordinate[1],
        crsOrigin: inputCrs,
        xTarget: coords[0],
        yTarget: coords[1],
        crsTarget: outputCrs,
        lat: coordsGeo[1],
        lon: coordsGeo[0],
      })
    },
    // metodo para cargar los datos inciales
    renderInitialData() {
      // this.coordinates = [];
      if (this.initialOriginSrs !== '') {
        this.inputCrs = this.initialOriginSrs;
      }
      if (this.initialTargetSrs !== '') {
        this.outputCrs = this.initialTargetSrs;
      }
      // procesar las coordenadas iniciales
      for (let index = 0; index < this.initialCoordinates.length; index++) {
        const coordinate = this.initialCoordinates[index];
        this.addCoordinate(coordinate, this.inputCrs, this.outputCrs);
      }
      if (this.addInitialPolygon && this.coordinates.length >= 3) {
        const firstPoint = this.coordinates[0];
        const lastPoint = this.coordinates[this.coordinates.length - 1];
        let polygonCoords = [];
        polygonCoords = this.coordinates.map((x) => [x.lat, x.lon]);
        // si las coordenadas del punto incial son difrentes a las del punto final
        // agregar la primera coordenada para cerar el polygono
        if (firstPoint.lat !== lastPoint.lat && firstPoint.lon !== lastPoint.lon) {
          polygonCoords.push([firstPoint.lat, firstPoint.lon]);
        }
        this.polygonCoords = polygonCoords;
      }
    },
    // metodo para agregar coordena con click en el mapa
    mapClick(e) {
      if (this.enableDraw) {
        if (this.outputCrs === null) {
          alert('¡Debe seleccionar un sistema de coordenadas de destino!');
          return;
        }
        const { lat, lng } = e.latlng;
        this.addCoordinate([lng, lat], 'EPSG:4326', this.outputCrs);
      }
    },
    // metodo para eliminar una coordenada se lanza desde la tabla
    deleteCoord(coordId) {
      this.coordinates = this.coordinates.filter((x) => x.id !== coordId);
    },
    // metodo para generar el archivo excel con las coordenadas
    download() {
      if (this.coordinates.length === 0) {
        alert('¡No hay coordenadas en el mapa para exportar!');
        return;
      }
      // hacer copia profunda de coordenadas 
      const coordinates = JSON.parse(JSON.stringify(this.coordinates));
      // eslint-disable-next-line
      var newCoordinates = coordinates.map(({ id, lat, lon, ...rest }) => rest);
      downloadExcel(newCoordinates, 'coords');
    },
    latLng
  }
};
</script>
