<template lang="pug">
v-card
  v-card-title
    v-text-field(
      dense
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details)
  v-data-table(
    dense
    :headers="headers"
    :items="coordinates"
    :search="search")
    template(class="d-flex flex-row" v-slot:item.options="{ item }")
      v-btn(
        color="gray"
        icon
        @click=""
        title="Enfocar punto")
        v-icon mdi-target
      v-btn(
        color="gray"
        icon
        @click="deleteCoordinate(item.id)"
        title="Eliminar punto")
        v-icon mdi-trash-can
</template>

<script>
import TextCutter from '@/components/TextCutter';

export default {
  name: 'CoordinatesPreview',

  components: {
    TextCutter,
  },

  props: {
    coordinates:{
      type: Array,
      required: true
    }
  },

  data () {
    return {
      search: '',
      // cabeceras de la tabla de coordenadas
      headers: [
        {
          text: 'punto',
          align: 'start',
          filterable: false,
          value: 'name',
        },
        { text: 'X Inicial', value: 'xOrigin' },
        { text: 'Y Inicial', value: 'yOrigin' },
        { text: 'CRS Origen', value: 'crsOrigin' },
        { text: 'X Destino', value: 'xTarget' },
        { text: 'Y Destino', value: 'yTarget' },
        { text: 'CRS Destino', value: 'crsTarget' },
        { text: 'Opciones', value: 'options' },
      ],
    }
  },

  computed: {
  },

  methods: {
    // metodo para eliminar una coordenada del componente
    deleteCoordinate(coordId) {
      this.$emit('deleteCoordinate', coordId);
    }
  }
}
</script>