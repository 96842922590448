<template lang="pug">
v-card
  v-card-title
    v-text-field(
      dense
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details)
  v-data-table(
    dense
    :headers="headers"
    :items="reportData.report_data"
    :search="search")
    template(
      v-if="headers.filter((x) => x.value === 'complementacion').length > 0"
      v-slot:item.complementacion="{ item }")
      text-cutter(
        v-if="item.complementacion"
        :text="item.complementacion"
        :text-length="50"
        modal-title="Complementación")
    template(
      v-if="headers.filter((x) => x.value === 'cabida').length > 0"
      v-slot:item.cabida="{ item }")
      text-cutter(
        v-if="item.cabida"
        :text="item.cabida"
        :text-length="50"
        modal-title="Cabida")
    template(
      v-if="headers.filter((x) => x.value === 'linderos_tecnicos').length > 0"
      v-slot:item.linderos_tecnicos="{ item }")
      text-cutter(
        v-if="item.linderos_tecnicos"
        :text="item.linderos_tecnicos"
        :text-length="50"
        modal-title="Linderos Tecnicos")
</template>

<script>
import { mapState } from 'vuex';
import TextCutter from '@/components/TextCutter';

export default {
  name: 'ReportPreview',

  components: {
    TextCutter,
  },

  data () {
    return {
      search: '',
    }
  },

  computed: {
    ...mapState('reports', ['reportData']),
    headers() {
      const headers = this.reportData.headers.map((x) => {return {text: x.name, value: x.variable}});
      return headers;
    }
  }
}
</script>