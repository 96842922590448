const ui = {
  namespaced: true,
  state: {
    // estado que almacena el nombre del componente renderizado
    bottomModalComponent: '',
    // esatdo que almacena las propiedades del componente dinamico
    bottomModalProperties: {},
    // estado que indica si se muestra el modal inferior
    bottomModalShow: false,
    // estado que almacena el titulo del modal inferior
    bottomModalTitle: '',
    showDialog: false,
    dialogTitle: '',
    dialogContent: '',
    dialogText: '',
    showAlert: false,
    alertMessage: '',
    alertType: 'success',
    showSidebar: false,
    showSideDialog: false,
    sideDialogTitle: '',
    sideDialogContent: '',
    sideDialogWidth: '40vw',
  },

  actions: {
    toggleDialog({rootState, commit}, status) {
      commit('TOGGLE_DIALOG', status);
      if (status === false) {
        // commit('folios/SELECT_MEASURE', null, { root: true });
        rootState.folios.selectedMeasure = null;
        rootState.folios.viewedMeasure = null;
      }
    }
  },

  mutations: {
    TOGGLE_DIALOG(state, status) {
      if (status === false) {
        state.dialogText = '';
      } 
      state.showDialog = status;
    },
    SET_DIALOG_CONTENT(state, componentName) {
      state.dialogContent = componentName;
    },
    SET_DIALOG_TEXT(state, text) {
      state.dialogText = text;
    },
    SET_DIALOG_TITLE(state, title) {
      state.dialogTitle = title;
    },
    SHOW_ALERT(state, {alertMessage, alertType}) {
      state.showAlert = true;
      state.alertMessage = alertMessage;
      state.alertType = alertType;
      setTimeout(() => state.showAlert = false, 5000);
    },
    TOGGLE_SIDEBAR(state, status) {
      state.showSidebar = status;
    },
    TOGGLE_SIDE_DIALOG(state, status) {
      if (status === false) {
        state.sideDialogTitle = '';
      } 
      state.showSideDialog = status;
    },
    SET_SIDE_DIALOG_CONTENT(state, componentName) {
      state.sideDialogContent = componentName;
    },
    SET_SIDE_DIALOG_TITLE(state, title) {
      state.sideDialogTitle = title;
    },
    SET_SIDE_DIALOG_WIDTH(state, width) {
      state.sideDialogWidth = width;
    },
    SHOW_BOTTOM_MODAL(state, {
      dialogTitle, componentName, properties
    }) {
      /**
       * Mutación para mostrar el modal inferior, para que el componente
       * se renderice debe ser importado en CommonBottonModal
       *
       * @param {Object} state con los elementos del estado del store
       * @param {Object}  payload los datos de la mutación
       * @returns {} No retorna nada
       * @example
       * $store.commit('ui/SHOW_BOTTOM_MODAL', {
       *  dialogTitle: '<title>',
       *  componentName: '<ComponentName>',
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      state.bottomModalComponent = componentName;
      state.bottomModalShow = true;
      state.bottomModalTitle = dialogTitle;
      state.bottomModalProperties = properties;
    },
    CHANGE_VALUE(state, { value, varName }) {
      /**
       * Mutación para cambiar el valor de un estado del store
       *
       * @param {Object} state con los elementos del estado del store
       * @param {Object}  payload los datos de la mutación
       * @returns {} No retorna nada
       * @example
       * $store.commit('ui/CHANGE_VALUE', {
       *  value: '<state_name>',
       *  varName: '<state_value>'
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      state[varName] = value;
    },
    CLEAR_BOTTOM_MODAL(state) {
      /**
       * Mutación para limpiar el modal
       *
       * @param {Object} state con los elementos del estado del store
       * @param {Object}  payload los datos de la mutación
       * @returns {} No retorna nada
       * @example
       * $store.commit('ui/CLEAR_BOTTOM_MODAL')
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      console.log('entro limpiar modal inferiror');
      state.bottomModalShow = false;
      state.bottomModalComponent = '';
      state.bottomModalTitle = '';
      state.bottomModalProperties = {};
    },
  }
};
  
export default ui;