<template lang="pug">
v-autocomplete(
  :disabled="disabled"
  v-model="selectedCsr"
  :items="systems"
  item-text="name"
  item-value="code"
  filled
  label="Seleccione un sistema")
  template(v-slot:selection="data")
    v-chip(
      v-bind="data.attrs"
      :input-value="data.selected"
      close
      @click="data.select"
      @click:close="selectedCsr = null")
      v-avatar(
        class="mr-2"
        :color="`${data.item.type === 'geo' ? 'green' : 'blue'}`")
        v-icon(
          size="20"
          color="white"
          v-html="`mdi-${data.item.type === 'geo' ? 'earth' : 'map-legend'}`")
      | {{ data.item.name }} ({{data.item.code}})
  template(v-slot:item="data")
    v-list-item-avatar
      v-avatar(
        :color="`${data.item.type === 'geo' ? 'green' : 'blue'}`"
        size="40")
        v-icon(
          size="25"
          color="white"
          v-html="`mdi-${data.item.type === 'geo' ? 'earth' : 'map-legend'}`")
    v-list-item-content
      v-list-item-title(v-html="data.item.name")
      v-list-item-subtitle
        | {{ data.item.code }}
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'CrsSelect',

  model: {
    prop: 'selectedCsr',
    event: 'change'
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    selectedCsr: null
  }),

  computed: {
    ...mapState('geos', {systems: 'coordinateSystems'})
  },

  watch: {
    selectedCsr: {
      handler(newVal) {
        this.$emit('change', newVal);
      }
    }
  }
}
</script>
