<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Información adicional del predio
  v-card-subtitle(class="pt-2 pb-0") Tipo Predio
  v-card-text(class="text--primary text-h4")
    div {{currentFolio.tipo_predio}}

  v-card-subtitle(class="pt-2 pb-0") Dirección
  v-card-text(class="text--primary text-h5")
    div {{currentFolio.direccion}}

  v-card-subtitle(class="pt-2 pb-0") Linderos
  v-card-text(class="text--primary text-caption")
    text-cutter(
      :text="currentFolio.cabida"
      :text-length="100"
      modal-title="Linderos del predio")
    v-btn(
      v-if="checkCoordinates(currentFolio.cabida)"
      small
      class="ma-2"
      color="amber"
      @click="extractCoordinates(currentFolio.cabida)") Ver coordenadas

  div(v-if="currentFolio.linderos_tecnicos")
    v-card-subtitle(class="pt-2 pb-0") Linderos Técnicos
    v-card-text(class="text--primary text-caption")
      text-cutter(
        :text="currentFolio.linderos_tecnicos"
        :text-length="100"
        modal-title="Linderos del predio")
      v-btn(
        v-if="checkCoordinates(currentFolio.linderos_tecnicos)"
        small
        class="ma-2"
        color="amber"
        @click="extractCoordinates(currentFolio.linderos_tecnicos)") Ver coordenadas

  v-card-subtitle(class="pt-2 pb-0") Complementaciones
  v-card-text(class="text--primary text-caption")
    text-cutter(
      :text="currentFolio.complementacion"
      :text-length="100"
      modal-title="Complementación del predio")
  v-divider
  div(class="d-flex flex-row py-2")
    v-card-subtitle(class="pt-2 pb-0") Área Terreno
    v-card-text(class="text--primary text-h6")
      div(v-if="currentFolio.area_terreno > -1") {{currentFolio.area_terreno.toFixed(2).replace('.', ',')}} m<sup>2</sup>
      div(v-else) Sin datos
    v-card-subtitle(class="pt-2 pb-0") Área Construcción
    v-card-text(class="text--primary text-h6")
      div(v-if="currentFolio.area_construida > -1") {{currentFolio.area_construida.toFixed(2).replace('.', ',')}} m<sup>2</sup>
      div(v-else) Sin datos
    v-card-subtitle(class="pt-2 pb-0") Área Privada
    v-card-text(class="text--primary text-h6")
      div(v-if="currentFolio.area_privada > -1") {{currentFolio.area_privada.toFixed(2).replace('.', ',')}} m<sup>2</sup>
      div(v-else) Sin datos
    v-card-subtitle(class="pt-2 pb-0") Destino Económico
    v-card-text(class="text--primary text-h6")
      div {{currentFolio.actividad_economica}}
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import TextCutter from '@/components/TextCutter';

export default {
  name: 'FolioSecondaryInfo',

  components: {
    TextCutter,
  },

  computed: {
    ...mapState('folios', ['currentFolio']),
    ...mapGetters('folios', ['folioColor']),
  },

  methods: {
    ...mapMutations('ui', ['SHOW_BOTTOM_MODAL', 'CLEAR_BOTTOM_MODAL']),
    // metodo para ver si los linderos tienen coordenadas
    checkCoordinates(boundaryText) {
      const regex = /[0-9]{6,7},?[0-9]{0,1}\s[NE]/;
      return regex.test(boundaryText);
    },
    // metodo para extraer las coordenadas de los linderos
    extractCoordinates(boundaryText) {
      // limpiar el modal inferiro
      this.CLEAR_BOTTOM_MODAL();
      const regex = /[0-9]{6,7},?[0-9]{0,1}\s[NE]/g;
      const regex2 = /[0-9]{6,7},?[0-9]{0,1}/
      // extraer coordenasa con expresiones regulares
      const rawCoordinates = [];
      var m;
      do {
          m = regex.exec(boundaryText);
          if (m) {
              let coordinate = regex2.exec(m[0])[0];
              coordinate = parseFloat(coordinate.replace(',', '.'));
              rawCoordinates.push(coordinate);
          }
      } while (m);
      // ordenar las coordenadas en pares
      const coordinates = []
      for (let index = 0; index < rawCoordinates.length/2; index++) {
        coordinates.push([rawCoordinates[index*2], rawCoordinates[(index * 2) + 1]]);
      }
      console.log(coordinates);
      // desplegar el modal inferior
      this.SHOW_BOTTOM_MODAL({
        dialogTitle: `Coordenadas cabida folio <b>${this.currentFolio.numero_matricula}</b>`,
        componentName: 'CoordinateManager',
        properties: {
          initialCoordinates: coordinates,
          initialOriginSrs: 'EPSG:3116',
          initialTargetSrs: 'EPSG:4326',
          addInitialPolygon: true,
        }
      });
    }
  }
}
</script>
